/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * This file is somewhat temporary while we have two versions of the gateway service.
 * The point is to have separate bundle files generated for v1 and v2 so that any given tenant
 * doesn't have to have load both
 */
import { lazy } from 'react';

const LazyAppV2 = lazy(() => import('./App'));

export default LazyAppV2;
